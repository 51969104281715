import theme from '@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index'

export default {
  ...theme,
  fonts: {
    body: '"Source Sans Pro", "Helvetica Neue", sans-serif',
    heading: '"Source Sans Pro", san-serif',
    code: '"Fira Code", monospace'
  },
  // section_hero: {
  //   display: 'none'
  // }
}